@import "svg-dimensions";

@mixin non-mobile {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin phone {
  @media screen and (max-width: 767px) {
    @content;
  }
}

@mixin landscape {
  @media screen and (min-aspect-ratio: 1/1) {
    @content;
  }
}

@mixin portrait {
  @media screen and (max-aspect-ratio: 1/1) {
    @content;
  }
}

@mixin touch {
  @media screen and (any-pointer: coarse) {
    @content;
  }
}

@mixin mouse {
  @media screen and (any-pointer: fine) {
    @content;
  }
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap; /* 1 */
}

%clickable {
  cursor: pointer;
  user-select: none;
  touch-action: manipulation;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.secretPath {
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  z-index: 5;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.25vw;
  line-height: 1.3;
  
  @include portrait {
    font-size: 6vw;
  }
}

body {
  display: flex;
  flex-direction: row-reverse;
  padding: 1.5em;
  min-height: 100vh;
  
  @include portrait {
    display: block;
    padding: 0;
  }
}

p,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1em;
}

ol,
ul {
  padding-left: 1em;
}

a {
  color: inherit;
  text-decoration: none;
}

.image-ratio {
  position: relative;
  
  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

svg {
  fill: currentColor;
  vertical-align: middle;
}

.fa {
  margin-right: .1em;
  
  svg {
    width: 1em;
    height: 1em;
    margin-top: -.2em;
  }
}

h1 {
  font-size: 3.3em;
  line-height: 1.05;
  margin-bottom: .8em;
  margin-right: 3em;
  
  @include portrait {
    font-size: 1.9em;
    line-height: 1.1;
    margin-right: 0;
  }
}

.portrait,
.content {
  flex: 1 0 50%;
}

.portrait {
  position: relative;
  position: sticky;
  top: 0;
  
  @include portrait {
    position: static;
  }

  .image-ratio {
    @include landscape {
      padding-bottom: 0 !important;
      position: static;
    }
  }
  
  img {
    object-position: top;
    object-fit: cover;
  }
}

.content {
  display: flex;
  flex-direction: column;
  padding: 1em 1em 0;
  
  @include portrait {
    padding: 1em 1.5em 0;
  }
}

.blocks {
  display: flex;
  flex-wrap: wrap;
  
  @include portrait {
    display: block;
  }
}

.block {
  position: relative;
  width: 50%;
  padding-right: 1em;
  
  @include portrait {
    padding-right: 0;
    width: auto;
  }
  
  &[data-width="full"] {
    width: 100%;
  }
  
  &-icon {
    position: absolute;
    left: 0;
    top: .3em;
    border: 2px solid;
    width: 2em;
    height: 2em;
    font-size: 1em;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    
    @include portrait {
      position: static;
      margin-bottom: .5em;
    }
    
    svg {
      margin: 0;
      vertical-align: top;
    }

    .fa {
      margin: 0;
    }
  }

  &-fields {
    margin-left: 2.65em;
  
    @include portrait {
      margin-left: 0;
    }
  }

  &-field {
    margin-bottom: .85em;
  
    @include portrait {
      margin-bottom: 1.5em;
    }
  }
}

.footer {
  margin-top: auto;
  margin-right: 1em;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  
  @include portrait {
    display: block;
    margin: 1em -1.5em 0;
    padding: 2em 0 3em;
    background: hsla(0,0%,0%,.03);
  }
}

.menu {
  a {
    position: relative;
    display: inline-block;
    margin-right: .8em;
    padding-right: .8em;
    font-weight: bold;
    
    @include portrait {
      display: block;
      margin: 0 0 3px;
      padding: .5em 1.5em;
    }
    
    &:after {
      content: "|";
      position: absolute;
      right: 0;
      top: 0;
      
      @include portrait {
        display: none;
      }
    }

    &:last-child {
      margin-right: 0;
      padding-right: 0;
      
      &:after {
        display: none;
      }
    }
  }
}

.language-menu {
  display: none;
  font-size: .7em;
  justify-content: flex-end;
  
  @include portrait {
    margin-top: 1em;
  }
  
  a {
    text-transform: uppercase;
    
    &:after {
      content: "/";
      margin: 0 .2em;
    }

    &:last-child:after {
      display: none;
    }
    
    &.is-active {
      font-weight: bold;
    }
  }
}